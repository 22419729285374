<template>
  <div class="emoji-container">
    <div class="emoji-icons">
      <EmojiItem v-for="(icon, index) in icons" :key="index" :icon="icon.svgPath" :count="icon.count"
        :hoverColor="icon.hoverColor" :clickColor="icon.clickColor" :voted="icon.voted"
        @toggleCount="toggleCount(index)" />
    </div>

  </div>
</template>

<script>
import EmojiItem from './EmojiItem.vue';
import { db } from '@/firebase';
import { doc, updateDoc, increment, onSnapshot } from 'firebase/firestore';
import { serverTimestamp } from "firebase/firestore";


export default {
  components: {
    EmojiItem,
  },
  props: {
    title: {
      type: String,
      required: true,
    },
    postId: {
      type: String,
      required: true,
      default: "1",
    },
  },
  data() {
    return {
      icons: [
        {
          svgPath: "M 16.697 5.5 c -1.222 -0.06 -2.679 0.51 -3.89 2.16 l -0.805 1.09 l -0.806 -1.09 C 9.984 6.01 8.526 5.44 7.304 5.5 c -1.243 0.07 -2.349 0.78 -2.91 1.91 c -0.552 1.12 -0.633 2.78 0.479 4.82 c 1.074 1.97 3.257 4.27 7.129 6.61 c 3.87 -2.34 6.052 -4.64 7.126 -6.61 c 1.111 -2.04 1.03 -3.7 0.477 -4.82 c -0.561 -1.13 -1.666 -1.84 -2.908 -1.91 z z",
          count: 0,
          voted: false,
          hoverColor: 'rgba(249,207,47, 0.2)',
          clickColor: 'rgba(249,207,47, 1)',
          emojiType: 'laugh',
        },
        {
          svgPath: "M 12 8.75 a 3.25 3.25 90 1 0 0 6.5 a 3.25 3.25 90 0 0 0 -6.5 z z M 3.1249 12 a 17.0729 17.0729 90 0 1 2.158 -2.6559 C 6.956 7.6684 9.244 6.15 12 6.15 c 2.756 0 5.0427 1.5184 6.7184 3.1941 A 17.0729 17.0729 90 0 1 20.8764 12 c -0.0754 0.1131 -0.1586 0.2379 -0.2535 0.3744 c -0.4355 0.624 -1.079 1.456 -1.9045 2.2815 C 17.0427 16.3316 14.7547 17.85 12 17.85 c -2.756 0 -5.0427 -1.5184 -6.7184 -3.1941 A 17.0742 17.0742 90 0 1 3.1236 12 z",
          count: 0,
          voted: false,
          hoverColor: 'rgba(0, 186, 124, 0.2)',
          clickColor: 'rgba(0, 186, 124, 1)',
          emojiType: 'question',
        },
        {
          svgPath: "M 16.697 5.5 c -1.222 -0.06 -2.679 0.51 -3.89 2.16 l -0.805 1.09 l -0.806 -1.09 C 9.984 6.01 8.526 5.44 7.304 5.5 c -1.243 0.07 -2.349 0.78 -2.91 1.91 c -0.552 1.12 -0.633 2.78 0.479 4.82 c 1.074 1.97 3.257 4.27 7.129 6.61 c 3.87 -2.34 6.052 -4.64 7.126 -6.61 c 1.111 -2.04 1.03 -3.7 0.477 -4.82 c -0.561 -1.13 -1.666 -1.84 -2.908 -1.91 z z",
          count: 0,
          voted: false,
          hoverColor: 'rgba(224, 36, 126, 0.2)',
          clickColor: 'rgba(224, 36, 126, 1)',
          emojiType: 'heart',
        },
        {
          svgPath: "M 5.8 7.15 C 5.8 6.184 6.5833 5.4 7.55 5.4 h 7.7 C 16.2167 5.4 17 6.184 17 7.15 v 12.908 l -5.6 -3.997 l -5.6 3.997 V 7.5 z",
          count: 0,
          voted: false,
          hoverColor: 'rgb(29, 155, 240, 0.2)',
          clickColor: 'rgb(29, 155, 240, 1)',
          emojiType: 'surprise',
        },
      ],
    };
  },
  mounted() {
    this.setupListener();
  },
  methods: {
    setupListener() {
      const postRef = doc(db, 'posts', this.postId);

      // Real-time listener to sync with Firestore
      onSnapshot(postRef, (doc) => {
        if (doc.exists()) {
          const data = doc.data();
          console.log("Real-time update from Firestore: ", data.emojiReactions);
          this.updateEmojiCounts(data.emojiReactions); // Sync emoji counts from Firestore
        }
      });
    },
    updateEmojiCounts(emojiReactions) {
      this.icons.forEach((icon) => {
        // Only update the count if emoji reaction exists in Firestore
        icon.count = emojiReactions[icon.emojiType] || 0;
      });
    },

    async toggleCount(index) {
      const icon = this.icons[index];
      const postRef = doc(db, 'posts', this.postId);

      try {
        // Fetch the most recent state of the document

        if (!icon.voted) {
          // Increment in Firestore
          await updateDoc(postRef, {
            [`emojiReactions.${icon.emojiType}`]: increment(1),
            updatedAt: serverTimestamp(),
          });
          console.log(`Incremented reaction for ${icon.emojiType}.`);
        } else {
          // Decrement in Firestore
          await updateDoc(postRef, {
            [`emojiReactions.${icon.emojiType}`]: increment(-1),
            updatedAt: serverTimestamp(),
          });
          console.log(`Decremented reaction for ${icon.emojiType}.`);
        }

        // No manual update to icon.count here, let the onSnapshot handle it

        // Toggle local voted state
        icon.voted = !icon.voted;

      } catch (error) {
        console.error("Error updating Firestore document: ", error);
      }
    }
  }
};
</script>

<style>
.emoji-container {
  display: flex;
  /* padding: 10px; */
}

.emoji-icons {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 20%;
  width: 100%;
}
</style>