<template>
  <li class="directory-container">
    <div id="search-row">
      <img id="user-pfp" :src="pfpUrl">
      <div id="main-column">
        <div class="info-row">
          <span id="name"><b>{{ displayName }}</b></span>
          <span id="username">@{{ userHandle }}</span>
          <span class="time"> ∙ {{ formattedTime }}</span>
        </div>
        <a :href="url" class="linked-title">{{ title }}</a>
        <div id="image-result" v-if="imageFlag">
          <img :src="imageUrl" class="search-image" />
        </div>

        <div v-if="postId !== '0'" id="search-react">
          <EmojiMenu :title="title" :postId="postId" />
        </div>


      </div>


    </div>





  </li>
</template>

<script>
import EmojiMenu from './EmojiMenu.vue';
import { db } from '@/firebase';
import { doc, getDoc, updateDoc } from 'firebase/firestore';

export default {
  name: 'TweetListitem',
  components: {
    EmojiMenu,
  },
  props: {
    title: {
      type: String,
      required: true,
    },
    url: {
      type: String,
      required: true,
    },
    postId: {
      type: String,
    },
    time: {
      type: Number,
      required: true, // Visit time in milliseconds since epoch
    },
    imageFlag: {
      type: Boolean,
    },
    userHandle: {
      type: String,
      default: 'psychobath'
    },
    displayName: {
      type: String,
      default: 'Display Name'
    },
    pfpUrl: {
      type: String,
      default: '@/assets/bliss.webp'
    },
  },
  data() {
    return {
      imageUrl: null, // To store the fetched image URL
      retriesLeft: 3, //max is 3
    };
  },
  async mounted() {
    if (this.imageFlag) {
      await this.loadImageUrl();
    }
  },
  computed: {
    formattedTime() {
      const now = Date.now();
      const diffInMs = now - this.time;
      const diffInMinutes = Math.floor(diffInMs / (1000 * 60));
      const diffInHours = Math.floor(diffInMinutes / 60);
      const diffInDays = Math.floor(diffInHours / 24);

      if (diffInDays >= 1) {
        return `${diffInDays}d`;
      } else if (diffInHours >= 1) {
        return `${diffInHours}h`;
      } else {
        return `${diffInMinutes}m`;
      }
    },
  },
  methods: {
    async loadImageUrl() {
      const postRef = doc(db, 'posts', this.postId);
      const docSnap = await getDoc(postRef);

      if (docSnap.exists() && docSnap.data().imageUrl) {
        this.imageUrl = docSnap.data().imageUrl;
        console.log("Loaded cached image:", this.imageUrl);
      } else {
        await this.fetchAndSaveImage(postRef);
      }
    },
    async fetchAndSaveImage(postRef) {
      if (this.retriesLeft-- <= 0) {
        this.imageUrl = '@/assets/headshot2.jpg';
        console.log("No valid image found; using placeholder.");
        return;
      }

      try {
        const response = await fetch(`https://www.googleapis.com/customsearch/v1?q=${encodeURIComponent(this.title)}&cx=${process.env.VUE_APP_GOOGLE_CX}&key=${process.env.VUE_APP_GOOGLE_API_KEY}&searchType=image&num=1`);
        const data = await response.json();
        const imageUrl = data.items?.[0]?.link;

        if (imageUrl && await this.isValidImage(imageUrl)) {
          this.imageUrl = imageUrl;
          await updateDoc(postRef, { imageUrl, updatedAt: new Date() });
          console.log("Fetched and saved image:", imageUrl);
        } else {
          throw new Error("Invalid image URL");
        }
      } catch (error) {
        console.log("Retrying fetch due to error:", error);
        await this.fetchAndSaveImage(postRef);
      }
    },
    isValidImage(url) {
      return new Promise(resolve => {
        if (url.includes('fbsbx')) {
          console.log("FACEBOOK URL SPOTTED / removed: ", url)
          resolve(false); // Automatically mark Facebook URLs as invalid
          return;
        }
        const img = new Image();
        img.onload = () => resolve(true);
        img.onerror = () => resolve(false);
        img.src = url;
      });
    },
  }
};
</script>

<style scoped>
.directory-container {
  padding: 3px 5px;
  border-bottom: 0.5px solid #ececec;
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 100%;
}

.info-row {
  display: flex;
  flex-direction: row;
  font-size: 16px;
  gap: 5px;
  font-family: 'Chirp';
  align-items: center;
}

#main-column {
  display: flex;
  flex-direction: column;
  gap: 2px;
  width: 100%;
}

#name {}

#username {
  color: grey
}

#search-react {
  width: 100%;
}

#search-row {
  padding: 0;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: horizontal;
  gap: 10px;
  width: 100%;
}

.linked-title {
  text-decoration: none;
  font-family: 'Chirp';
  color: rgb(29, 155, 240);
  margin-bottom: 2px;
  /* font-size: larger; */
}

.linked-title:hover {
  text-decoration: underline;
}

#user-pfp {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  object-fit: cover;
  aspect-ratio: 1 / 1;
  align-items: center;
}

#search-icon {
  width: 10px;
  height: 10px
}

.time {
  color: rgb(152, 151, 151);
  font-size: 14px;
  margin-left: -5px;
}

#image-result {
  margin: 10px 0px;
}

.search-image {
  width: 80%;
  height: auto;
  border-radius: 8px;
  object-fit: cover;
}
</style>
