<template>
  <div id="app-container">
    <LeftSidebar :scrollDirection="scrollDirection" @changePage="currentPage = $event" />

    <main id="main-content">
      <div id="header-wrapper">
        <HeaderComponent :currentPage="currentPage" :currentView="currentView" :scrollDirection="scrollDirection"
          @toggleView="toggleView" @changePage="currentPage = $event" />
      </div>

      <div v-if="currentPage === 'home'">
        <TimelineComponent v-if="currentView === 'recent' && history.every(entry => entry.postId !== '0')"
          class="timeline" :history="history" />
        <AltTimeline v-else-if="currentView === 'alt' && history.every(entry => entry.postId !== '0')"
          class="alt-timeline" :history="history" />
      </div>

      <div v-else-if="currentPage === 'explore'">
        <ExploreMenu :currentPage="currentPage" :trendingSearches="trendingSearches" />
      </div>

      <div v-else-if="currentPage === 'about'">
        <AboutPage :activeUsers="activeUsers" :totalVisitors="totalVisitors" :history="history" />
      </div>

      <div v-else-if="currentPage === 'feed'">
        <TweetTimeline v-if="currentView === 'recent'" class="tweet-timeline" :history="history" />
        <AltTweettimeline v-else-if="currentView === 'alt' && trendingSearches.length" class="tweet-alt-timeline"
          :trendingSearches="trendingSearches" />
      </div>

      <div v-else-if="currentPage === 'directory'">
        <PeepleDirectory :users="users" />
      </div>

    </main>

    <RightSidebar :currentPage="currentPage" :trendingSearches="trendingSearches" />
  </div>
</template>

<script>
import TimelineComponent from './components/TimelineComponent.vue';
import AltTimeline from './components/AltTimeline.vue';
import AboutPage from './components/AboutPage.vue';
import LeftSidebar from './components/LeftSidebar.vue';
import RightSidebar from './components/RightSidebar.vue';
import HeaderComponent from './components/HeaderComponent.vue';
import ExploreMenu from './components/ExploreMenu.vue';
import TweetTimeline from './components/TweetTimeline.vue';
import AltTweettimeline from './components/AltTweettimeline.vue';
import PeepleDirectory from './components/PeepleDirectory.vue';


const APIURL = "https://staging.searchhistory.site/api"

// this will have to be moved to dotenv later
// this will also have to updated via nginx and dist via proxy

export default {
  name: 'App',
  components: {
    TimelineComponent,
    AltTimeline,
    TweetTimeline,
    AltTweettimeline,
    LeftSidebar,
    RightSidebar,
    AboutPage,
    HeaderComponent,
    ExploreMenu,
    PeepleDirectory,
  },
  data() {
    return {
      history: [],
      trendingSearches: [],
      users: [],
      currentPage: 'home',
      currentView: 'recent',
      activeUsers: 0,
      totalVisitors: 0,
      scrollDirection: "scroll-up",
      lastScrollY: 0,
    };
  },
  async mounted() {
    console.log('App mounted - BEFORE adding scroll listener');
    window.addEventListener('scroll', this.handleScroll);
    window.addEventListener('scroll', () => this.handleScroll());
    this.connectSSE();
    await this.pingSubscribers();
    await this.fetchData(); // Fetch API data on component mount
    await this.fetchTrendingSearches();
  },
  methods: {
    getUniqueUsers(history) {
      const uniqueUsersMap = new Map();

      history.forEach(post => {
        // Only add user if we haven't seen their userGID before
        if (!uniqueUsersMap.has(post.userGID)) {
          uniqueUsersMap.set(post.userGID, {
            userGID: post.userGID,
            displayName: post.displayName,
            handle: post.handle,
            pfpUrl: post.pfpUrl,
            // Add metadata about user's activity
            lastActive: post.visitTime,
            postCount: 1
          });
        } else {
          // Update post count and last active time for existing users
          const user = uniqueUsersMap.get(post.userGID);
          user.postCount++;
          // Update lastActive if this post is more recent
          if (post.visitTime > user.lastActive) {
            user.lastActive = post.visitTime;
          }
        }
      });

      // Convert Map to array and sort by lastActive
      return Array.from(uniqueUsersMap.values())
        .sort((a, b) => b.lastActive - a.lastActive);
    },
    async fetchData() {
      try {
        // Fetch data from the backend
        const response = await fetch(APIURL);

        if (!response.ok) {
          console.error('Failed to fetch data:', response.statusText);
          return;
        }

        // Parse the response JSON
        const data = await response.json();
        console.log("here's the total from postsdb", data)

        // Update the local state
        this.history = data;
        console.log('Fetched and updated history:', this.history);
        this.users = this.getUniqueUsers(this.history)
        console.log("users is: ", this.users)
        this.assignImages(this.history);
      } catch (error) {
        console.error('Error fetching data:', error.message);
      }
    },

    toggleView(view) {
      this.currentView = view;
      console.log("view is now: ", this.currentView)
    },
    connectSSE() {
      const sse = new EventSource('https://staging.searchhistory.site/sse');
      console.log("SSE event added")

      // Listen for visitor count updates
      sse.addEventListener('updateCurrVisitors', event => {
        console.log("curr visitor event listener on")
        const data = JSON.parse(event.data);
        this.activeUsers = data.activeUsers;
      });

      // Listen for cumulative visitor count updates
      sse.addEventListener('updateTotalVisitors', event => {
        const data = JSON.parse(event.data);
        this.totalVisitors = data.totalVisitors;
      });
    },
    handleScroll() {
      const currentScrollY = window.scrollY;
      console.log('Scrolling - Current:', currentScrollY, 'Last:', this.lastScrollY);

      if (currentScrollY > this.lastScrollY) {
        console.log('Setting scroll-down');
        this.scrollDirection = "scroll-down";
      } else if (currentScrollY < this.lastScrollY) {
        console.log('Setting scroll-up');
        this.scrollDirection = "scroll-up";
      }
      this.lastScrollY = currentScrollY;
    },
    async pingSubscribers() {
      try {
        const response = await fetch('/ping-subs');
        if (!response.ok) {
          console.error('Failed to ping subscriptions:', response.statusText);
        } else {
          console.log('Subscriptions pinged successfully');
        }
      } catch (error) {
        console.error('Error pinging subscriptions:', error.message);
      }
    },
    async fetchTrendingSearches() {
      console.log("starting fetchTrending")

      const trending = this.history.map(postData => ({
        ...postData,
        totalReactions: Object.values(postData.emojiReactions || {}).reduce(
          (acc, count) => acc + count, 0
        ),
        imageUrl: postData.imageUrl || '/default-image.jpg'
      }));

      // Sort by total reactions
      trending.sort((a, b) => b.totalReactions - a.totalReactions);

      // Update the trendingSearches data
      this.trendingSearches = trending;

      console.log('Fetched trending searches:', this.trendingSearches);
    },
    assignImages(historyItems) {
      historyItems.forEach(item => {
        item.getsImage = false;
      });
      const basePosition = 3;
      for (let i = basePosition; i < historyItems.length;) {
        const interval = Math.floor(Math.random() * 3) + 1;
        const randomOffset = Math.floor(Math.random() * 3) - 1;
        const targetIndex = i + randomOffset;
        if (targetIndex >= 0 && targetIndex < historyItems.length) {
          historyItems[targetIndex].getsImage = true;
        }
        i += interval;
      }
    },
  },
  unmounted() {
    console.log('Removing scroll listener...'); // Debug log
    // document.removeEventListener('scroll', this.handleScroll); // Make sure to clean up
    // or if using main-content:
    window.removeEventListener('scroll', this.handleScroll);
  }
}
</script>

<style>
@font-face {
  font-family: 'Mukta';
  src: url('@/assets/fonts/Mukta-Regular.ttf') format('truetype');
}

@font-face {
  font-family: 'Chirp';
  src: url('@/assets/fonts/chirp-regular-web.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Chirp';
  src: url('@/assets/fonts/chirp-bold-web.woff') format('woff');
  font-weight: bold;
  font-style: normal;
}

/* Your existing styles remain unchanged */

html,
body {
  margin: 0;
  padding: 0;
  height: 100%;
  /* Ensure full height */
  overflow-x: hidden;
  width: 100%;
  /* Ensure full width */
  box-sizing: border-box;
  /* Prevent padding/border from affecting dimensions */
}

#app-container {
  display: grid;
  grid-template-columns: 20% 1fr 30%;
  grid-template-areas: "left-sidebar main-content right-sidebar";
  height: 100vh;
  font-family: 'Inter', Arial;
  margin: 0 auto;
  max-width: 1200px;
}

#main-content {
  grid-area: "main-content";
}


#header-wrapper {
  position: sticky;
  top: 0;
  z-index: 10;
  width: 100%;
}

@media (max-width: 1024px) {
  #app-container {
    grid-template-columns: 10% 1fr 35%;
    grid-template-areas: "left-sidebar main-content right-sidebar";
  }
}

@media (max-width: 768px) {
  #app-container {
    grid-template-columns: 100%;
    grid-template-areas: "main-content";
  }

  #main-content {
    width: 100%;
  }
}
</style>
