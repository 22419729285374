<template>
  <aside id="right-sidebar">

    <div id="rightbar-stuff">
      <div id="searchbar">
        <SearchBar />
      </div>

      <div id="trending-searches">
        <TrendingSearches :trendingSearches="trendingSearches" />
      </div>
      <div id="user-display">
        <UserDisplay />
      </div>
    </div>

  </aside>
</template>

<script>
import TrendingSearches from './TrendingSearches.vue';
import UserDisplay from './UserDisplay.vue';
import SearchBar from './SearchBar.vue'

export default {
  name: 'RightSidebar',
  components: {
    SearchBar,
    TrendingSearches,
    UserDisplay,
  },
  props: {
    trendingSearches: {
      type: Array,
      required: true,
      default: () => []
    }
  }
};
</script>

<style scoped>
/* Right Sidebar styles */
#right-sidebar {
  padding: 3px 20px;
  border-left: 0.5px solid #e6e5e5;
}

#rightbar-stuff {
  position: sticky;
  top: 0;
  gap: 20px;
  display: flex;
  flex-direction: column;
}

#right-sidebar>div {
  min-height: 50px;
}

#user-display {
  border-radius: 10px;
  border: 0.5px solid #eaeaea;
}

#trending-searches {
  border-radius: 10px;
  border: 0.5px solid #f0eeee;
}

@media (max-width: 768px) {
  #right-sidebar {
    display: none;
  }
}

/* other styles */
</style>
