<template>
  <aside :class="scrollDirection" id="left-sidebar">
    <div id="sidebar-stuff">
      <div class="icon-container" id="logo-container">
        <div class="icon-link" @click.prevent="$emit('changePage', 'home')">
          <img id="title-logo" src="../assets/eye-search.png" />
        </div>
        <div class="icon-link" @click.prevent="$emit('changePage', 'home')">
          <img src="../assets/home-icon.svg" alt="Home" />
          <span class="icon-label">Home</span>
        </div>
        <div class="icon-link" id="search-link" @click.prevent="$emit('changePage', 'explore')">
          <img src="../assets/magnifying.svg" alt="Explore" />
          <span class="icon-label">Explore</span>
        </div>
        <div class="icon-link" @click.prevent="$emit('changePage', 'about')">
          <img src="../assets/profile.webp" alt="Info" />
          <span class="icon-label">Profile</span>
        </div>
        <div class="icon-link" @click.prevent="$emit('changePage', 'feed')">
          <img src="../assets/twitter.svg" alt="ID feed" />
          <span class="icon-label">Altview</span>
        </div>
        <div class="icon-link" @click.prevent="$emit('changePage', 'directory')">
          <img src="../assets/look-people.png" alt="users directory" />
          <span class="icon-label">Peeple *wip*</span>
        </div>
      </div>
    </div>

  </aside>
</template>

<script>

export default {
  name: 'LeftSidebar',
  props: {
    scrollDirection: {
      type: String,
      required: true,
      default: 'scroll-up'
    },
  },
};
</script>

<style scoped>
/* Left Sidebar styles */
#left-sidebar {
  /* padding: 0px 20px; */
  display: flex;
  flex-direction: column;
  align-items: center;
  align-content: horizontal;
  border-right: 0.5px solid #e6e5e5;
}

#sidebar-stuff {
  /* margin-right: -50%; */
  justify-content: top;
  display: flex;
  flex-direction: column;
  position: sticky;
  top: 0;
}

#title-logo {
  /* width: 20px;
  height: auto; */
}

.icon-container {
  flex-direction: column;
  display: flex;
  gap: 15px;
  /* margin-right: 15px; */
  align-items: flex-start;
}

.icon-container img {
  width: 24px;
  height: 24px;
  cursor: pointer;
}

.icon-link {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: auto;
  border-radius: 50px;
  transition: background-color 0.2s ease;
  gap: 10px;
  padding: 10px 15px;
}

.icon-link:hover {
  background-color: rgba(171, 170, 170, 0.2);
}

.icon-label {
  display: inline-block;
  font-size: 17px;
}

#user-display {
  position: fixed;
  bottom: 0;
}

@media (max-width: 1024px) {
  #left-sidebar {
    width: 80px;
    /* Optional: reduce width */
  }

  .icon-label {
    display: none;
    /* Hide icon labels */
  }
}

@media (max-width: 768px) {
  #title-logo {
    display: none;
  }

  #left-sidebar {
    position: fixed;
    bottom: 0;
    left: 0;
    border-top: 1px solid #e6e5e5;
    height: 60px;
    width: 100%;
    padding: 0;
    background-color: rgba(255, 255, 255, 1);
    transition: background-color 0.3s ease;
    justify-content: center;
    display: flex;
  }

  #left-sidebar.scroll-down {
    background-color: rgba(255, 255, 255, 0.5);
  }

  #left-sidebar.scroll-up {
    background-color: rgba(255, 255, 255, 1);
  }

  /* Adjust sidebar-stuff positioning */
  #sidebar-stuff {
    display: flex;
    width: 100%;
    margin: 0;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
    padding: 0;
  }

  .icon-container {
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
    display: flex;
    padding: 0;
    flex: 1;
  }

  .icon-link {
    justify-content: center;
    display: flex;
    flex: 0;
    padding: 0;
    align-items: center;
  }

}
</style>
