<template>
  <div class="explore-container">
    <div class="search-box" :class="{ 'focused': isFocused }">
      <img id="search-icon" src="@/assets/magnifying.svg">
      <input type="text" class="search-input" placeholder="Search" v-model="searchQuery" @focus="isFocused = true"
        @blur="isFocused = false" @input="handleSearch" />
    </div>

    <div id="trending-image" v-if="trendingSearches && trendingSearches.length">
      <img :src="trendingSearches[0].imageUrl ? trendingSearches[0].imageUrl : require('@/assets/bliss.webp')" />
    </div>

    <div class="trending-list" v-if="trendingSearches && trendingSearches.length">
      <!-- Loop through trending searches and render the TrendingSearch component for each -->
      <TrendingSearch v-for="(post) in trendingSearches.slice(0, 4)" :key="post.postId" :title="post.title"
        :url="post.url" :totalReactions="post.totalReactions" :postId="post.postId" />
    </div>
  </div>
</template>

<script>

import TrendingSearch from './TrendingSearch.vue'

export default {
  name: 'ExploreMenu',
  data() {
    return {
      searchQuery: '',
      isFocused: false,
    }
  },
  components: {
    TrendingSearch
  },
  props: {
    trendingSearches: {
      type: Array,
      required: true,
      default: () => []
    }
  },
  methods: {
    handleSearch() {
      this.$emit('search', this.searchQuery)
    },
    clearSearch() {
      this.searchQuery = ''
      this.handleSearch()
    },
  }
}
</script>

<style scoped>
.explore-container {
  gap: 3px;
  display: flex;
  flex-direction: column
}

.trending-list {
  margin: 0px 5px;
}

.search-box {
  background-color: #efefef;
  border-radius: 50px;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 12px;
  transition: background-color 0.2s;
  height: 30px;
  margin: 5px;
}

.search-box.focused {
  background-color: #efefef;
  border: 1px solid #1da1f2;
}

#search-icon {
  width: 15px;
  height: 15px;
}

.search-input {
  background: transparent;
  border: none;
  font-size: 15px;
  width: 100%;
  outline: none;
}

.search-input::placeholder {
  color: #71767b;
}

.clear-icon {
  width: 5px;
  height: 5px;
  cursor: pointer;
}

.clear-svg {
  fill: #1da1f2;
  width: 100%;
  height: 100%;
}

#trending-image img {
  max-width: 100%;
}

@media (max-width: 768px) {
  .explore-container {
    margin-bottom: 60px;
  }
}
</style>