<template>
  <div class="trending-container">
    <h1>What's happening</h1>

    <div id="top-image">
      <!-- Display the image for the first trending search -->
      <img v-if="trendingSearches.length"
        :src="trendingSearches[0].imageUrl ? trendingSearches[0].imageUrl : require('@/assets/bliss.webp')"
        alt="Top trending image" />
    </div>

    <div class="trending-list" v-if="trendingSearches && trendingSearches.length">
      <!-- Loop through trending searches and render the TrendingSearch component for each -->
      <TrendingSearch v-for="(post) in trendingSearches.slice(0, 4)" :key="post.postId" :title="post.title"
        :url="post.url" :totalReactions="post.totalReactions" :postId="post.postId" />
    </div>
  </div>
</template>

<script>
import TrendingSearch from './TrendingSearch.vue';


export default {
  name: 'TrendingSearches',
  components: {
    TrendingSearch,
  },
  props: {
    trendingSearches: {
      type: Array,
      required: true,
      default: () => []
    }
  },
};
</script>

<style scoped>
.trending-container {
  margin: 20px;
  gap: 0px;
  justify-content: left;
  align-items: left;
}

.trending-container h1 {
  font-size: 20px;
  font-weight: bold;
  color: rgb(0, 0, 0);
  margin-bottom: 5px;
  font-family: 'Chirp'
}

.trending-list {
  display: flex;
  flex-direction: column;
  gap: 0px;
}

#top-image img {
  width: 30%;
  height: auto;
}

#top-image {
  margin-bottom: 20px;
  text-align: center;
  font-size: 14px;
  color: gray;
}
</style>
