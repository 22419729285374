<template>
  <div id="tweet-timeline">
    <TweetListitem v-for="(item, index) in sortedHistory" :key="index" :title="item.title" :url="item.url"
      :postId="item.postId || 'default-postId'" :time="item.visitTime" :imageFlag="item.getsImage"
      :userHandle="item.handle" :displayName="item.displayName" :pfpUrl="item.pfpUrl" />
  </div>
</template>

<script>
import TweetListitem from './TweetListitem.vue';

export default {
  name: 'TweetTimeline',
  components: {
    TweetListitem,
  },
  props: {
    history: {
      type: Array,
      required: true,
    },
  },
  computed: {
    sortedHistory() {
      // Sort history items by visit time (most recent first)
      return [...this.history].sort((a, b) => b.visitTime - a.visitTime);
    },
  },
};
</script>

<style scoped>
#tweet-timeline {
  margin-left: 10px;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

@media (max-width: 768px) {
  #tweet-timeline {
    margin-left: 10px;
  }
}
</style>
